



















































































































import { defineComponent, reactive, ref, toRefs, computed } from '@vue/composition-api';
import axios from 'axios';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useUserState } from '@/store';

const MAX_TEAMS_PER_PAGE = 10;
export default defineComponent({
  name: 'JoinTeam',
  components: {
    MCreateTeam: () => import('./m-create-team.vue')
  },
  props: {
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    teamList: {
      type: Array,
      default: () => {}
    },
    teamDoc: {
      type: Object,
      default: () => {}
    },
    updateAdk: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const state = reactive({
      page: 1,
      joinTeamDialog: false,
      password: '',
      error: ''
    });
    const maxTeamMembers = ref(3);
    const isLoading = ref(false);
    const selectedTeam: any = ref(null);
    const { user } = useUserState(['user']);
    const numPages = computed(() => Math.ceil(props.teamList?.length / MAX_TEAMS_PER_PAGE));
    const visibleTeams = computed(() =>
      props.teamList?.slice(
        (state.page - 1) * MAX_TEAMS_PER_PAGE,
        (state.page - 1) * MAX_TEAMS_PER_PAGE + MAX_TEAMS_PER_PAGE
      )
    );

    async function joinTeam() {
      if (state.password === selectedTeam.value.password) {
        try {
          isLoading.value = true;
          const data = {
            team_id: selectedTeam.value._id,
            public_id: props?.programDoc?.data?._id?.toString()
          };

          const teamRes = await axios.post(
            `${process.env.VUE_APP_STUDENT_SERVICE}/team/join`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
              }
            }
          );
          if (teamRes.data.data) {
            props.updateAdk();
            isLoading.value = false;
            ctx.emit('update', teamRes.data.data);
            selectedTeam.value = null;
            state.joinTeamDialog = false;
          }
        } catch (error) {
          console.log(error);
          isLoading.value = false;
        }
      } else {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Incorrect Password',
          type: 'error',
          isShowSnackbar: true
        });
      }
    }

    const numMembers = val => {
      return val.members?.length;
    };
    return {
      ...toRefs(state),
      numMembers,
      maxTeamMembers,
      isLoading,
      joinTeam,
      visibleTeams,
      selectedTeam,
      numPages,
      user
    };
  }
});
